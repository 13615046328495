#page-document {
  .ui.container.ask-librarian {
    .leadimage-container {
      margin-bottom: 4rem;
    }
    .form-container {
      margin-top: 4rem;
      margin-bottom: 4rem;
      .form-success {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }

      .form-check {
        input:checked + label.form-check-label {
          &:before {
            border-color: transparent $secondary $secondary transparent;
          }

          &:after {
            border-color: $secondary;
          }
        }
      }
    }
  }
}
