.card-body {
  .category-top {
    .custom-bim-location {
      display: block;
      width: 100%;
      margin: 0;
    }
    .location {
      font-weight: bold;
      transform: uppercase;
    }

    .text:not(:empty) + .location::before {
      content: ' - ';
      font-weight: 300;
    }
    .text:not(:empty) + .custom-bim-location::before {
      content: none;
    }
    .custom-bim-location + .data::before {
      content: none !important;
    }

    .time:before {
      display: inline-block;
      margin: 0 8px;
      content: '—';
    }
  }
}
