//site
$primary-h: 240 !default;
$primary-s: 95 !default;
$primary-b: 15 !default;

$dark-background-title: #fff !default;
$bim-background: #000066;
$casa-piani-background: #cc0000;
$archivio-storico-background: rgb(148, 42, 140);

$primary: white;
$primary-text: black;
$secondary: #000066;
$secondary-text: white;
$tertiary: #757575;
$tertiary-text: $tertiary-text;
$link-color: #4b71b3;
$footer-color: #000066;
$header-color: #000066;

$focus-outline-color: $secondary;
