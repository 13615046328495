.container .block .searchSections:not(.button) {
  .searchSections-background picture {
    height: 100%;

    img {
      height: 100%;
      object-position: center;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .searchContainer {
    margin: 100px 0 165px;

    .searchbar {
      button {
        $btn-color: #3f4142;
        background-color: $btn-color;
        transition: background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:active,
        &:hover {
          background-color: darken($btn-color, 10%);
        }
      }
    }
  }
}
