.scopri-contenuti {
  .scopri-contenuti-wrap {
    @media (max-width: 1219px) {
      justify-content: center;
    }

    .scopri-contenuti-block {
      width: 360px;
      min-width: 360px;
      height: 125px;
      background: #dae2f0;

      &:hover {
        background: darken(#dae2f0, 10%);
      }
    }

    .icon-color {
      fill: $secondary !important;
    }
  }
  @media (max-width: 992px) {
    .d-flex.justify-content-between.align-items-center.mb-3.scopri-contenuti-block.mr-3 {
      margin-right: 0 !important;
    }
  }
}
