.newsletter-view {
  /* MailChimp Form Embed Code - Classic - 12/17/2015 v10.7 */
  #mc_embed_signup {
    // form {
    //   display: block;
    //   position: relative;
    //   text-align: left;
    //   padding: 10px 0 10px 3%;
    // }
    // h2 {
    //   font-weight: bold;
    //   padding: 0;
    //   margin: 15px 0;
    //   font-size: 1.4em;
    // }
    input {
      border: 1px solid #abb0b2;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
    }
    input[type='checkbox'] {
      -webkit-appearance: checkbox;
    }
    input[type='radio'] {
      -webkit-appearance: radio;
    }
    input:focus {
      border-color: #333;
    }
    // .button {
    //   clear: both;
    //   background-color: #aaa;
    //   border: 0 none;
    //   border-radius: 4px;
    //   transition: all 0.23s ease-in-out 0s;
    //   color: #ffffff;
    //   cursor: pointer;
    //   display: inline-block;
    //   font-size: 15px;
    //   font-weight: normal;
    //   height: 32px;
    //   line-height: 32px;
    //   margin: 0 5px 10px 0;
    //   padding: 0 22px;
    //   text-align: center;
    //   text-decoration: none;
    //   vertical-align: top;
    //   white-space: nowrap;
    //   width: fit-content;
    //   width: -moz-fit-content;
    // }
    // .button:hover {
    //   background-color: #777;
    // }
    .small-meta {
      font-size: 11px;
    }
    .nowrap {
      white-space: nowrap;
    }

    .mc-field-group {
      clear: left;
      position: relative;
      width: 96%;
      padding-bottom: 3%;
      min-height: 50px;
    }
    .size1of2 {
      clear: none;
      float: left;
      display: inline-block;
      width: 46%;
      margin-right: 4%;
    }
    * html & .size1of2 {
      margin-right: 2%; /* Fix for IE6 double margins. */
    }
    .mc-field-group label {
      display: block;
      margin-bottom: 3px;
    }
    .mc-field-group input {
      display: block;
      width: 100%;
      padding: 8px 0;
      text-indent: 2%;
    }
    .mc-field-group select {
      display: inline-block;
      width: 99%;
      padding: 5px 0;
      margin-bottom: 2px;
    }

    .datefield,
    .phonefield-us {
      padding: 5px 0;
    }
    .datefield input,
    .phonefield-us input {
      display: inline;
      width: 60px;
      margin: 0 2px;
      letter-spacing: 1px;
      text-align: center;
      padding: 5px 0 2px 0;
    }
    .phonefield-us .phonearea input,
    .phonefield-us .phonedetail1 input {
      width: 40px;
    }
    .datefield .monthfield input,
    .datefield .dayfield input {
      width: 30px;
    }
    .datefield label,
    .phonefield-us label {
      display: none;
    }

    .indicates-required {
      text-align: right;
      font-size: 11px;
      margin-right: 4%;
    }
    .asterisk {
      color: #e85c41;
      font-size: 150%;
      font-weight: normal;
      position: relative;
      top: 5px;
    }
    .clear {
      clear: both;
    }
    .foot {
      display: grid;
      grid-template-columns: 3fr 1fr;
      width: 96%;
      align-items: center;
    }
    @media screen and (max-width: 400px) {
      .foot {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        align-items: center;
      }
    }

    @media screen and (max-width: 400px) {
      .referralBadge {
        width: 50%;
      }
    }

    .brandingLogo {
      justify-self: right;
    }
    @media screen and (max-width: 400px) {
      .brandingLogo {
        justify-self: left;
      }
    }

    .mc-field-group.input-group ul {
      margin: 0;
      padding: 5px 0;
      list-style: none;
    }
    .mc-field-group.input-group ul li {
      display: block;
      padding: 3px 0;
      margin: 0;
    }
    .mc-field-group.input-group label {
      display: inline;
    }
    .mc-field-group.input-group input {
      display: inline;
      width: auto;
      border: none;
    }

    div#mce-responses {
      float: left;
      top: -1.4em;
      padding: 0em 0.5em 0em 0.5em;
      overflow: hidden;
      width: 90%;
      margin: 0 5%;
      clear: both;
    }
    div.response {
      margin: 1em 0;
      padding: 1em 0.5em 0.5em 0;
      font-weight: bold;
      float: left;
      top: -1.5em;
      z-index: 1;
      width: 80%;
    }
    #mce-error-response {
      display: none;
    }
    #mce-success-response {
      color: #529214;
      display: none;
    }
    label.error {
      display: block;
      float: none;
      width: auto;
      margin-left: 1.05em;
      text-align: left;
      padding: 0.5em 0;
    }

    #mc-embedded-subscribe {
      clear: both;
      width: auto;
      display: block;
      margin: 1em 0 1em 5%;
    }
    #num-subscribers {
      font-size: 1.1em;
    }
    #num-subscribers span {
      padding: 0.5em;
      border: 1px solid #ccc;
      margin-right: 0.5em;
      font-weight: bold;
    }

    #mc-embedded-subscribe-form div.mce_inline_error {
      display: inline-block;
      margin: 2px 0 1em 0;
      padding: 3px;
      background-color: rgba(255, 255, 255, 0.85);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: normal;
      z-index: 1;
      color: #e85c41;
    }
    #mc-embedded-subscribe-form input.mce_inline_error {
      border: 2px solid #e85c41;
    }
  }
}
