@import 'design-comuni-plone-theme/theme/_site-variables.scss';
@import 'variables';
@import 'design-comuni-plone-theme/theme/site.scss';

@import 'bootstrap-override/bootstrap-italia-site';

@import 'Views/chiediAlBibliotecarioView';
@import 'Views/newsletterView';

@import 'Blocks/scopri-contenuti';
@import 'Blocks/suggerimentiBiblioteca';
@import 'Blocks/search-sections';
@import 'Blocks/searchInCatalogs';
@import 'Blocks/cardWithImageTemplate';
@import 'Blocks/photogalleryTemplate';
@import 'Blocks/hero';

.public-ui .link-list-wrapper ul li a span {
  color: #4b71b3;
  display: inline-block;
  margin-right: 24px;
}
.public-ui .link-list-wrapper ul li a:hover:not(.disabled) span {
  color: #465d85;
  text-decoration: underline;
}

.public-ui .card-title a,
.public-ui .genericcard .card-title a {
  color: #4b71b3;
  font-size: 1.125rem;
  line-height: 1.625rem;
}

// custom style for dots in calendar block
.calendar .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  .calendar-pagination-button {
    outline: none;
    border: none;
    background: transparent;
    margin: 0 0.5rem;
  }
}

.public-ui {
  .it-header-slim-wrapper {
    font-weight: 600;
  }

  .it-header-navbar-wrapper {
    .menu-wrapper {
      border-top: 1px solid transparentize($color: #fff, $amount: 0.5);
    }
  }

  .it-header-wrapper {
    .it-header-center-wrapper {
      background-color: $primary;

      .it-header-center-content-wrapper {
        .it-brand-text h2.no_toc {
          color: $primary-text;
        }
      }
    }

    .navbar .navbar-collapsable .navbar-nav li.megamenu a.nav-link {
      font-weight: 700;
    }
  }

  .overflow-auto {
    overflow: auto;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    .it-header-navbar-wrapper {
      background-color: $primary;
    }
    .it-header-wrapper {
      .it-header-center-wrapper {
        height: 160px;
      }
    }
  }
}

@media (max-width: 991px) {
  .event-search {
    .d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
      justify-content: space-between !important;

      .mr-lg-3.my-2.my-lg-1.filter-wrapper.text-filter {
        min-width: 45% !important;

        input {
          text-overflow: ellipsis;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .event-search {
    .d-flex.search-container.align-items-center.justify-content-center.flex-wrap {
      flex-direction: column !important;
      justify-content: space-between !important;

      .mr-lg-3.my-2.my-lg-1.filter-wrapper.text-filter,
      .mr-lg-3.my-2.my-lg-1.filter-wrapper.select-filter,
      .mr-lg-3.my-2.my-lg-1.filter-wrapper.date-filter {
        min-width: 100% !important;

        input {
          text-overflow: ellipsis;
        }

        .DateRangePicker {
          width: 100% !important;
        }
      }
    }
  }
}

.subsite-root {
  #briciole {
    display: none;
  }

  .documentFirstHeading {
    display: none;
  }
}

@import 'bim.scss';
