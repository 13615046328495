.searchSections.searchInCatalogs {
  background-position: center;
  // padding: 10px 50px 0px 50px !important;
  background-size: cover;

  @media (max-width: 767px) {
    input.inputSearch.lightgrey-bg-c2 {
      width: 100%;
      height: 100%;
      padding: 0 3rem 0 0 !important;
      border: none;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-weight: 500;
      outline: 0;
      text-overflow: ellipsis;
      -webkit-transition: 0.2s ease-out;
      -o-transition: 0.2s ease-out;
      transition: 0.2s ease-out;
    }
  }
}
