body.cms-ui,
.public-ui {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $secondary;
  }
  .public-ui a,
  a {
    color: #4b71b3;
  }
  #page-login {
    .help a {
      color: #4b71b3;
      &:hover {
        color: #465d85;
      }
    }
    button#login-form-submit.ui.basic.button.primary {
      color: #007eb1 !important;
    }
  }

  .block.listing .event-recurrences-more {
    color: #4b71b3;
  }

  .page-sharing.dropdown .btn-dropdown small,
  .page-actions.dropdown .btn-dropdown small {
    color: #4b71b3;
  }

  .link-list-wrapper ul li button.btn-link {
    color: #4b71b3;
  }

  .link-list-wrapper ul li button.btn-link:hover {
    color: #465d85 !important;

    .icon {
      fill: #4b71b3;
    }
  }

  .public-ui a:hover,
  .nav-tabs .nav-link:hover,
  a:hover {
    color: #465d85;
  }
  .card-title a:hover {
    color: #465d85 !important;
  }
  // fix search colors
  .toggles label input[type='checkbox']:checked + .lever {
    background-color: #4b71b3;
  }
  .select-all-cts.btn.btn-link.btn-mini {
    color: #4b71b3 !important;
    &:hover {
      color: #465d85;
    }
  }

  .public-ui
    .chip:not(:disabled):not(.chip-primary):not(.chip-secondary).selected {
    background-color: $tertiary;
  }
  #search-modal {
    .modal-header .ml-auto.btn.btn-outline-primary.text-capitalize {
      color: $secondary;
      background-color: transparent;
      background-image: none;
      -webkit-box-shadow: inset 0 0 0 1px $secondary;
      box-shadow: inset 0 0 0 1px $secondary;
    }
    .search-filters.text-center .btn.btn-primary {
      background-color: $tertiary;
      color: $primary;
      svg {
        fill: $primary;
      }
    }
    .nav-tabs .nav-link.active {
      color: $secondary;
    }
  }

  // fix menu items and navlinks
  .navbar .dropdown-menu .link-list-wrapper .link-list.bordered li h3 a span {
    color: $primary-text !important;
  }

  .navbar .dropdown-menu .link-list-wrapper ul li a span,
  .navbar .dropdown-menu .link-list-wrapper ul li a h3,
  .navbar .dropdown-menu .link-list-wrapper ul li a i {
    color: #4b71b3 !important;
  }

  :focus,
  .form-check [type='checkbox']:focus + label,
  .form-check [type='radio']:focus + label,
  .toggles label input[type='checkbox']:focus + .lever {
    border-color: $secondary !important;
    box-shadow: 0 0 0 2px $secondary !important;
    -webkit-box-shadow: 0 0 0 2px $secondary !important;
  }

  .navbar.it-navscroll-wrapper .link-list-wrapper ul li a.active span {
    color: #4b71b3;
  }
  .link-list-wrapper ul li a:hover:not(.disabled) .icon {
    fill: #4b71b3;
  }
  // fix cookie banner
  .gdpr-privacy-banner {
    .btn-black {
      background-color: $secondary;
    }
    .gdpr-privacy-content-wrapper
      .gdpr-privacy-settings
      .settings-column
      .ui.checkbox.toggle
      input:checked
      ~ label:before {
      background-color: #4b71b3;
    }
  }
  // fix block hero in edit
  .block.inner.hero {
    @media only screen and (min-width: 1223px) and (max-width: 1446px) {
      width: 723px !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    @media only screen and (min-width: 1447px) and (max-width: 1654px) {
      width: 933px !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
    @media only screen and (min-width: 1655px) {
      width: 1127px !important;
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  // otherwise everything is white
  .block.iconBlocks {
    .card .iconblock-title,
    .block-header .title {
      color: $secondary;
    }
    .card .iconblock-icon svg {
      fill: $secondary;
    }
  }
  .argumentInEvidence {
    .read-more {
      color: $secondary !important;
      svg {
        fill: $secondary !important;
      }
    }
    h2 {
      color: $primary !important;
    }
  }
  // fix react select not using :focus as set site wide
  .event-search,
  #search-sort-on {
    .react-select__control--is-focused,
    .react-select__control--is_focused {
      border-color: white !important;
      box-shadow: 0 0 0 2px white !important;
      -webkit-box-shadow: 0 0 0 2px white !important;
      outline: none;
    }
    .react-select__option {
      color: $primary-text !important;
    }
    .search-container {
      .filter-wrapper {
        flex: unset !important;
      }
    }
  }
  // fix dateInputs not using :focus as set site wide
  .search-block-filters
    .search-container
    .filter-wrapper.date-filter
    .DateRangePickerInput
    .DateInput_input.DateInput_input__focused,
  .event-search
    .search-container
    .filter-wrapper.date-filter
    .DateRangePickerInput
    .DateInput_input.DateInput_input__focused,
  .bandi-search
    .search-container
    .filter-wrapper.date-filter
    .DateRangePickerInput
    .DateInput_input.DateInput_input__focused {
    border-color: white !important;
    box-shadow: 0 0 0 2px white !important;
    -webkit-box-shadow: 0 0 0 2px white !important;
    outline: none;
  }

  .btn-outline-primary {
    color: $primary-text;

    &:hover {
      color: $primary-text;
    }
  }
  // fix footer
  .it-footer .it-footer-main {
    background-color: $footer-color;

    a:focus {
      border-color: $dark-background-title !important;
      box-shadow: 0 0 0 2px $dark-background-title !important;
      outline: none;
    }

    a {
      color: white;
      text-decoration: underline;

      &:hover {
        color: darken($secondary-text, 30%);
      }
    }

    .it-brand-wrapper {
      padding-bottom: 1rem !important;
      margin-top: 1rem;

      a {
        display: flex;
        align-items: baseline !important;
        text-decoration: none;
      }

      .it-brand-text {
        h2.bim-footer {
          color: white;
          font-family: 'Titillium Web';
          font-size: 32px !important;
          font-weight: 550;
          letter-spacing: 0;
          line-height: 42px;
        }
      }
    }

    h4 {
      padding: 1.25rem 0 1rem 0.25rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.7);
      margin-bottom: 1.25rem;
      color: white;
      font-size: 16px;
      text-decoration: none;
      text-transform: uppercase;

      a {
        text-decoration: none;

        &:hover {
          color: #465d85;
        }

        &:focus {
          border-color: $tertiary !important;
          box-shadow: 0 0 0 2px $tertiary !important;
          outline: none;
        }
      }
    }

    .link-list-wrapper,
    .bim-block-text,
    form.form-newsletter {
      padding: 0 0.25rem;
    }

    .link-list-wrapper {
      ul {
        li {
          a {
            text-decoration: underline;
          }
          span {
            color: #4b71b3;
          }
        }
      }
    }

    form.form-newsletter {
      button {
        background-color: $tertiary;
        color: white !important;

        &:hover,
        &:focus {
          background-color: darken($tertiary, 10%);
        }

        .icon {
          display: none;
        }
      }
    }

    .bim-block-text {
      p {
        margin-bottom: 0 !important;
      }
    }
  }

  &.cms-ui {
    .it-footer-main {
      display: none;
    }
  }

  @media (min-width: 992px) {
    .menu-wrapper #it-subsite-slim-header {
      display: none !important;
    }
  }

  .bimFooterLogo {
    img {
      width: 100px;
      height: auto;
      margin-left: -0.5rem;
      @media (max-width: 570px) {
        margin-left: 0.5rem !important;
      }
    }
    margin-right: 1rem;
  }

  // per ora nascondo il footer del sottosito
  .footer {
    display: none;
  }

  .it-footer .it-footer-small-prints {
    background: $footer-color;

    .it-footer-small-prints-list {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1rem;
        right: 1rem;
        height: 1px;
        background-color: #fff;
      }
    }

    a {
      text-decoration: underline;
    }

    button {
      padding: 0;
      border: none;
      margin: 0;
      background: none;
      color: #fff;
      font-size: 15px;
      text-decoration: underline;
    }
  }

  // sezione pagina argomento bim
  &.section-bim {
    .argomento-breadcrumbs {
      max-width: 95%;
      margin: auto;
      color: $primary;

      .breadcrumb-container {
        padding: 1.5em;

        .breadcrumb {
          margin-bottom: 0;

          .breadcrumb-item {
            a {
              color: white;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .ArgomentoTitleWrapper,
    .ArgomentoHeader {
      .wrap-word {
        overflow-wrap: break-word;
      }

      .col-lg-6 {
        min-width: 100%;
        max-width: 100%;
      }

      .col-lg-6.title-description-wrapper::before,
      .col-lg-12.title-description-wrapper::before {
        display: block;
        min-width: 10rem;
        max-width: 30rem;
        min-height: 10rem;
        max-height: 30rem;
        margin-top: -0.5rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
        background: url('../src/components/ImolaTheme/Logo/logo.png') no-repeat;
        background-size: contain;
        content: '';
        float: left;
      }

      .col-lg-4.offset-lg-2 {
        display: none;
      }
    }

    @media (max-width: 768px) {
      .ArgomentoTitleWrapper {
        .col-lg-6.title-description-wrapper {
          padding: 1rem 0;
        }

        .col-lg-6.title-description-wrapper::before,
        .col-lg-12.title-description-wrapper::before {
          display: flex;
          width: 144px;
          min-width: unset;
          max-width: unset;
          min-height: 8rem;
          align-items: center;
          justify-content: center;
          margin: -1rem 0 1rem 0;
          float: none;
        }

        .col-lg-12.title-description-wrapper {
          padding-right: 0 !important;
        }
      }
    }

    #portal-header-image::after {
      background-color: $bim-background !important;
      opacity: 1;
    }
  }

  // sezione pagina argomento archivio
  &.section-archivio-storico {
    .argomento-breadcrumbs {
      max-width: 95%;
      margin: auto;
      color: $primary;

      .breadcrumb-container {
        padding: 1.5em;

        .breadcrumb {
          margin-bottom: 0;

          .breadcrumb-item {
            a {
              color: white;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .ArgomentoTitleWrapper,
    .ArgomentoHeader {
      .wrap-word {
        overflow-wrap: break-word;
      }

      .col-lg-6 {
        min-width: 100%;
        max-width: 100%;
      }

      .col-lg-6.title-description-wrapper::before,
      .col-lg-12.title-description-wrapper::before {
        display: block;
        min-width: 10rem;
        max-width: 30rem;
        min-height: 10rem;
        max-height: 30rem;
        margin-top: 0.5rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
        background: url('../src/components/ImolaTheme/Images/archivio-storico-logo.png')
          no-repeat;
        background-size: contain;
        content: '';
        float: left;
      }
    }
    @media (max-width: 768px) {
      .ArgomentoTitleWrapper {
        .col-lg-6.title-description-wrapper {
          padding: 1rem 0;
        }

        .col-lg-6.title-description-wrapper::before,
        .col-lg-12.title-description-wrapper::before {
          display: flex;
          width: 144px;
          min-width: unset;
          max-width: unset;
          min-height: 8rem;
          align-items: center;
          justify-content: center;
          margin: -1rem 0 1rem 0;
          float: none;
        }

        .col-lg-12.title-description-wrapper {
          padding-right: 0 !important;
        }
      }
    }

    #portal-header-image::after {
      background-color: $archivio-storico-background !important;
      opacity: 1;
    }
    .block.iconBlocks {
      .card .iconblock-icon svg {
        fill: $archivio-storico-background !important;
      }
    }
  }

  // sezione pagina argomento casa piani
  &.section-casa-piani {
    .argomento-breadcrumbs {
      max-width: 95%;
      margin: auto;
      color: $primary;

      .breadcrumb-container {
        padding: 1.5em;

        .breadcrumb {
          margin-bottom: 0;

          .breadcrumb-item {
            a {
              color: white;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .ArgomentoTitleWrapper,
    .ArgomentoHeader {
      .wrap-word {
        overflow-wrap: break-word;
      }

      .col-lg-6 {
        min-width: 100%;
        max-width: 100%;
      }

      .col-lg-6.title-description-wrapper::before,
      .col-lg-12.title-description-wrapper::before {
        display: block;
        min-width: 10rem;
        max-width: 30rem;
        min-height: 10rem;
        max-height: 30rem;
        margin-top: 0.5rem;
        margin-right: 2rem;
        margin-bottom: 1rem;
        background: url('../src/components/ImolaTheme/Images/casa-piani-logo.png')
          no-repeat;
        background-size: contain;
        content: '';
        float: left;
      }
    }
    @media (max-width: 768px) {
      .ArgomentoTitleWrapper {
        .col-lg-6.title-description-wrapper {
          padding: 1rem 0;
        }

        .col-lg-6.title-description-wrapper::before,
        .col-lg-12.title-description-wrapper::before {
          display: flex;
          width: 144px;
          min-width: unset;
          max-width: unset;
          min-height: 8rem;
          align-items: center;
          justify-content: center;
          margin: -1rem 0 1rem 0;
          float: none;
        }

        .col-lg-12.title-description-wrapper {
          padding-right: 0 !important;
        }
      }
    }

    #portal-header-image::after {
      background-color: $casa-piani-background !important;
      opacity: 1;
    }
    .block.iconBlocks {
      .card .iconblock-icon svg {
        fill: $casa-piani-background !important;
      }
    }
  }

  // sezione pagina argomento biblioteche decentrate
  &.section-biblioteche-decentrate {
    .argomento-breadcrumbs {
      max-width: 95%;
      margin: auto;
      color: $primary;

      .breadcrumb-container {
        padding: 1.5em;

        .breadcrumb {
          margin-bottom: 0;

          .breadcrumb-item {
            a {
              color: white;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .ArgomentoTitleWrapper,
    .ArgomentoHeader {
      .wrap-word {
        overflow-wrap: break-word;
      }

      .col-lg-6.col-lg-6.title-description-wrapper {
        min-width: 100%;
        max-width: 100%;
      }
    }

    #portal-header-image::after {
      background-color: $bim-background !important;
      opacity: 1;
    }
  }

  // fix mappa
  .fullwidth-map {
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: center;

    #geocoded-result {
      width: 100%;
      height: 600px;
    }
  }

  .image-gallery-image {
    width: 88% !important;
    height: 408px;
    object-fit: unset !important;
  }

  // fix image gallery
  .image-gallery-content {
    &:hover {
      cursor: pointer;
    }

    .image-gallery-icon.image-gallery-right-nav,
    .image-gallery-left-nav {
      color: #4b71b3;
      box-shadow: none;
      filter: none;
    }

    .image-gallery-description {
      bottom: 0;
      width: 88%;
      left: 6%;
      line-height: 1.5;
      font-size: 2rem;
      font-weight: 600;
    }
    .image-gallery-bullets {
      bottom: -28px;
      .image-gallery-bullet {
        width: 16px;
        height: 16px;
        background-color: #4b71b340;
        box-shadow: none;
        outline: none;
        &.active {
          background-color: #4b71b3;
        }
      }
    }
  }

  // sfondi sezioni
  &.section-archivio-storico {
    .bg-light {
      background-color: $archivio-storico-background !important;

      h2 {
        color: white;
      }
    }
  }

  &.section-bim {
    .bg-light {
      background-color: $bim-background !important;

      h2 {
        color: white;
      }
    }
  }

  &.section-casa-piani {
    .bg-light {
      background-color: $casa-piani-background !important;

      h2 {
        color: white;
      }
    }
  }

  //  fix blocco in evidenza
  .in-evidence {
    .bg-light {
      background-color: $primary !important;
    }

    .in-evidence-cards-wrapper {
      display: -ms-grid;
      display: grid;
      gap: 1.3rem;
      -ms-grid-columns: 1fr 1.3rem 1fr 1.3rem 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }

    .card-wrapper {
      &:first-child {
        grid-column: 1;
        grid-row: 1 / 3;
      }
    }

    // smartphone
    @media (max-width: 585px) {
      .in-evidence-cards-wrapper {
        .card-wrapper {
          width: 100%;
          margin: 0px;
        }
        display: block;
        height: 100%;
      }
    }

    // tablet
    @media (max-width: 1367px) and (min-width: 586px) {
      .in-evidence-cards-wrapper {
        grid-template-columns: auto auto;
      }
    }
  }

  .card-slide-text-template {
    .box {
      .category {
        background-color: $primary;
        color: $primary-text;

        &::after {
          border-top-color: $primary;
        }
      }
    }
  }

  //  fix quadratoni
  .squares-image-template {
    .box {
      &::before {
        background-color: transparentize($tertiary, 0.15);
      }

      &:hover::before {
        background-color: $tertiary;
      }
    }
  }

  .sliderTemplate .slick-arrow .icon {
    color: transparentize($color: $tertiary, $amount: 0.6);

    &:hover,
    &:focus {
      color: transparentize($color: $tertiary, $amount: 0.4);
    }
  }

  // fix cerca nelle sezioni
  .searchSections.searchInCatalogs {
    .searchContainer {
      .buttonsContainer {
        a.btn-primary {
          margin: 10px 10px 10px 0;
          background-color: $bim-background !important;
          color: $secondary-text !important;

          &:hover {
            color: $secondary-text !important;
          }
        }
      }
    }
  }

  .container .block .searchSections.searchInCatalogs {
    .searchSections-background {
      background-color: $tertiary;
    }

    .searchContainer {
      margin: 56px 0;
    }

    .buttonsContainer {
      button {
        background-color: $secondary !important;
        color: white !important;
      }
    }
  }

  .card-slide-text-template .grid .bg-img {
    background-color: $secondary;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .card-slide-text-template .box .read-more a:hover {
    color: #fff;
    fill: #fff;
    text-decoration: none;
  }
  .icon-primary {
    fill: #4b71b3 !important;
  }
  .pagination .page-item .page-link[aria-current] {
    border: 1px solid $secondary !important;
    color: $secondary !important;
    pointer-events: none;
  }
  .pagination .page-item .page-link:hover {
    color: $secondary !important;
    background-color: transparent;
  }

  .chip.chip-primary {
    border-color: #4b71b3;
    color: #4b71b3;

    &:hover {
      border-color: $secondary;
      background-color: #4c4b4b24;
      & > .chip-label {
        color: $secondary;
      }
    }

    .chip-label {
      color: #4b71b3;
    }
  }

  .simple-card-default {
    .data:before {
      content: none;
    }

    .card.card-teaser {
      background-color: rgba(218, 226, 240, 1);

      &:after {
        content: none;
      }

      .categoryicon-top .icon {
        width: 32px;
        height: 32px;
        fill: $secondary;
        font-weight: 600;
      }

      .card-body {
        .read-more {
          color: $secondary !important;

          svg {
            fill: $secondary !important;
          }
        }

        a {
          &:link,
          &:visited {
            color: #3f4142;
            text-decoration: none;
          }
        }

        a {
          &:hover,
          &:active {
            color: darken(#3f4142, 10%);
            text-decoration: underline;
          }
        }

        .card-title {
          margin-bottom: 10px;

          a {
            color: $secondary !important;
            &:hover,
            &:active {
              text-decoration: underline;
            }
          }
        }
      }

      .it-card-footer {
        flex: 1 1 100%;
        align-self: flex-end;

        a {
          &:hover,
          &:active {
            text-decoration: underline;
          }

          &:link,
          &:visited {
            text-decoration: none;
          }
        }

        .icon {
          min-width: unset;
        }
      }
    }
  }

  .simple-card-compact-template {
    .card-teaser-wrapper {
      background-color: rgba(218, 226, 240, 1);

      &.card-teaser-block-3 {
        & > .card-teaser {
          flex-wrap: nowrap;
        }
      }
    }
  }

  // calendario
  .block .calendar {
    .calendar-header {
      background-color: $secondary;
    }

    .path-filter-buttons {
      .btn-outline-primary {
        border: 1px solid currentColor;
        color: $secondary;

        &:hover,
        &:focus {
          color: darken($secondary, 10%);
        }
      }
    }

    .btn-primary {
      border: 1px solid $secondary;
      background-color: $secondary;
      color: white !important;

      &:hover,
      &:focus {
        border-color: darken($secondary, 10%);
        background-color: darken($secondary, 10%);
      }
    }

    .slick-dots.dot {
      bottom: -54px;
    }
  }

  .link-button.text-center {
    @media (max-width: 767px) {
      margin-top: 1rem;
    }
    margin-top: 4.5rem;
  }

  .bg-light {
    background-color: #eceff1 !important;
  }

  .bg-primary {
    background-color: $primary !important;
  }

  .bg-secondary {
    background-color: $secondary !important;
  }

  .argumentInEvidence-background {
    background-color: #eceff1 !important;
  }

  .menu-wrapper {
    @media (min-width: 768px) {
      #it-slim-header {
        display: none !important;
      }
    }

    .d-md-flex {
      flex-direction: column;

      a.nav-link {
        padding: 13px 24px !important;
      }
    }

    .d-md-flex::after {
      width: 100%;
      max-width: 65px;
      height: 1px;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 1.5rem;
      background: rgba(92, 111, 130, 0.5);
      content: '';
    }
  }

  .it-brand-wrapper {
    svg {
      display: none;
    }
    @media (max-width: 380px) {
      .it-brand-text {
        padding-right: 0.5rem !important;
      }
    }

    a {
      display: flex;
      align-items: start !important;
    }

    a figure {
      margin-bottom: 0rem;

      width: 100px;
      height: auto;
    }

    a figure img {
      @media (max-width: 570px) {
        width: 60px;
        height: auto;
        margin-right: 0.75rem !important;
        margin-left: 0.5rem !important;
      }

      @media (min-width: 571px) and (max-width: 767px) {
        width: 70px;
        height: auto;
        margin-right: 0.75rem !important;
        margin-left: 0.5rem !important;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        width: 80px;
        height: auto;
      }
    }

    a .it-brand-text h2 {
      margin-top: 0.85rem;
      @media (min-width: 1001px) and (max-width: 1199px) {
        margin-top: 0.45rem;
      }
      @media (min-width: 768px) and (max-width: 1000px) {
        margin-top: 1rem;
      }
      @media (max-width: 767px) {
        margin-top: 0.65rem;
        margin-bottom: 0.5rem;
      }
      @media (min-width: 1200px) {
        margin-top: 1.013rem;
      }
    }
  }

  .it-header-navbar-wrapper {
    .container {
      padding: 0 0.25rem;
    }
  }

  @media (max-width: 992px) {
    .it-header-slim-wrapper {
      padding: 6px 0 !important;
    }

    .it-header-slim-wrapper
      .it-header-slim-wrapper-content
      .it-header-slim-right-zone.header-slim-right-zone {
      justify-content: flex-end;
    }

    .it-header-center-wrapper {
      .container {
        padding: 0 0.25rem;
      }
    }

    .it-header-center-wrapper
      .it-header-center-content-wrapper
      .it-search-wrapper
      a.rounded-icon {
      width: 32px;
      height: 32px;
      border-radius: 16px;

      svg {
        width: 32px;
      }
    }
  }

  .parent-site-menu li.nav-item:last-child {
    border-right: none;
  }
  .parent-site-menu li.nav-item {
    border-right: 1px solid $primary;
  }
  .parent-site-menu li.nav-item a.nav-link {
    color: $primary;
  }

  .it-header-slim-wrapper {
    background-color: $header-color;
  }

  .it-header-navbar-wrapper {
    background-color: transparent !important;

    .container .row .col-12 {
      #navigation button.custom-navbar-toggler svg.icon {
        fill: $bim-background !important;
      }
    }

    .nav-link {
      color: black !important;

      svg {
        fill: black !important;
      }
    }
  }

  .public-ui {
    .it-header-wrapper {
      .it-header-center-wrapper {
        .it-brand-wrapper a .icon {
          width: 100px;
          height: auto;
        }

        .it-header-center-content-wrapper {
          .it-brand-wrapper a,
          .it-brand-wrapper a h3,
          .it-right-zone,
          .it-right-zone .it-socials ul .icon {
            color: #3a3a3a;
            fill: #3a3a3a;
          }

          .it-search-wrapper a.rounded-icon {
            background: #3a3a3a;

            svg {
              fill: white;
            }

            &:focus,
            &:hover {
              background: darken(#3a3a3a, 10%);
            }
          }
        }
      }
    }

    .it-header-navbar-wrapper {
      .menu-wrapper {
        border-top: 0;
      }
    }

    li.breadcrumb-item a:focus {
      border-color: $secondary !important;
      box-shadow: 0 0 0 2px $focus-outline-color !important;
      outline: none;
    }

    .navbar .navbar-collapsable .navbar-nav li a.nav-link.active {
      border-bottom: 3px solid transparent !important;

      span {
        color: $secondary !important;
        font-weight: 600;
        text-decoration: underline;
        text-decoration-thickness: 4px;
      }
    }

    .navbar
      .navbar-collapsable
      .menu-wrapper
      .nav-item.megamenu
      .dropdown-menu
      .link-list-wrapper
      ul.link-list
      li
      a:hover {
      color: darken(#4b71b3, 50%) !important;
      font-weight: 500;
    }

    .navbar.has-megamenu li.nav-item.active .dropdown a.nav-link,
    .navbar.has-megamenu li.nav-item.active .dropdown a.nav-link {
      border-color: $secondary !important;
    }
  }
  .icon-argument-container {
    background-color: $secondary;
    svg {
      color: $primary;
      fill: $primary;
    }
  }
  @media (min-width: 1200px) {
    section .row .px-1:not(:nth-child(4n)) {
      padding: 0 2rem 0 0 !important;
    }
  }
  @media (max-width: 575px) {
    section .row .px-1 {
      padding: 0 1rem !important;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    section .row .px-1:not(:nth-child(2n)) {
      padding: 0 2rem 0 0 !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    section .row .px-1:not(:nth-child(3n)) {
      padding: 0 2rem 0 0 !important;
    }

    section .row .px-1.col-lg-3,
    section .row .px-1.col-md-3 {
      max-width: 33.33% !important;
      flex: 0 0 33.33% !important;
    }
  }
}

.public-ui
  .point-list-wrapper
  .point-list
  .point-list-aside.point-list-warning {
  color: #a66300;
  border-color: #a66300;
  min-width: 130px;
}
