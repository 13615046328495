.navbar {
  .navbar-collapsable {
    .navbar-nav {
      li {
        a {
          &.nav-link {
            @media (min-width: #{map-get($grid-breakpoints, lg)}) {
              padding-right: 12px;
              padding-left: 12px;
            }
          }
        }
      }
    }
  }
}
