.block.hero {
  .block-inner-wrapper {
    z-index: unset !important;
    max-height: 25rem;

    .hero-image {
      background-color: rgba(0, 0, 0, 0.75);
      object-fit: cover;
    }

    .hero-body {
      position: relative;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      overflow-y: scroll;
    }
  }
  @media (max-width: 992px) {
    .hero-image {
      width: 100%;
      max-width: 100%;
      max-height: 400px;
      margin: auto;
      background-color: rgba(0, 0, 0, 0.75);
    }

    .block-inner-wrapper {
      max-height: unset;
    }

    .hero-body {
      max-height: 20rem;
      overflow-y: scroll;
    }
  }

  .absolute {
    position: absolute;
  }

  .hero-card-container {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
  }

  .overlay {
    position: fixed; /* Sit on top of the page content */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    cursor: pointer; /* Add a pointer on hover */

    div.dialog-body {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      .image {
        max-width: 50%;
      }
    }
  }
}
