.block.photogallery {
  .slick-track {
    align-items: flex-start;
  }

  a {
    text-decoration: none;
    figure.img-wrapper figcaption {
      text-align: start;

      .image-title {
        text-transform: uppercase;
        color: $modal-heading-color;
        font-size: 0.8333333333rem;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .image-caption {
        color: $body-color;
        line-height: 1.1rem;
      }
    }
  }

  .slick-slider {
    .slick-dots .play-pause-wrapper button svg {
      color: $secondary;
    }

    .slick-arrow svg {
      color: $secondary;
      fill: $secondary;
    }
  }
}
